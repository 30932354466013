@keyframes donut-fill {
	to {
		stroke-dasharray: 0 100;
	}
}

@keyframes text-slideup {
	from {
		opacity: 0;
		transform: translateY(0%);
	}
	to {
		opacity: 1;
		transform: translateY(-50%);
	}
}

.donut {
	width: 12.5rem;
	height: 12.5rem;
	position: relative;
	margin: auto;
}

.donut__frame,
.donut__circle {
	stroke-width: 0.125rem;
	fill: none;
}

.donut__frame {
	stroke: #ccc;
}

.donut__canvas {
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
	z-index: 2;
}

.donut__circle {
	stroke: #000;
	stroke-linecap: round;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
	animation: donut-fill 2s reverse;
}

.donut__circle[stroke-dasharray='0 100'] {
	stroke: none;
}

.donut__text {
	font-family: monospace;
	font-size: 3rem;
	letter-spacing: -0.15rem;
	text-align: center;
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	z-index: 3;
	transform: translateY(-50%);
	animation: text-slideup 2s linear;
}

.donut.is--negative .donut__circle {
	transform: rotate(-90deg) scaleY(-1);
}

.donut-complex text {
	font-family: monospace;
	font-size: 1rem;
	font-weight: bold;
	text-shadow: 0 0 0.5rem #fff;
	opacity: 0;
	transition: opacity 0.35s ease-in-out 1s;
}

.donut-complex circle {
	fill: none;
	transition: stroke-dashoffset 1s ease-in-out;
}

.donut-complex:not(.donut-complex--loaded) circle {
	stroke-dashoffset: 0;
}

.donut-complex--loaded text {
	opacity: 1;
}
