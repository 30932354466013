@keyframes donut-fill {
  to {
    stroke-dasharray: 0 100;
  }
}

@keyframes text-slideup {
  from {
    opacity: 0;
    transform: translateY(0%);
  }

  to {
    opacity: 1;
    transform: translateY(-50%);
  }
}

.donut {
  width: 12.5rem;
  height: 12.5rem;
  margin: auto;
  position: relative;
}

.donut__frame, .donut__circle {
  stroke-width: .125rem;
  fill: none;
}

.donut__frame {
  stroke: #ccc;
}

.donut__canvas {
  z-index: 2;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.donut__circle {
  stroke: #000;
  stroke-linecap: round;
  transform-origin: 50%;
  animation: 2s reverse donut-fill;
  transform: rotate(-90deg);
}

.donut__circle[stroke-dasharray="0 100"] {
  stroke: none;
}

.donut__text {
  letter-spacing: -.15rem;
  text-align: center;
  z-index: 3;
  font-family: monospace;
  font-size: 3rem;
  animation: 2s linear text-slideup;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.donut.is--negative .donut__circle {
  transform: rotate(-90deg)scaleY(-1);
}

.donut-complex text {
  text-shadow: 0 0 .5rem #fff;
  opacity: 0;
  font-family: monospace;
  font-size: 1rem;
  font-weight: bold;
  transition: opacity .35s ease-in-out 1s;
}

.donut-complex circle {
  fill: none;
  transition: stroke-dashoffset 1s ease-in-out;
}

.donut-complex:not(.donut-complex--loaded) circle {
  stroke-dashoffset: 0;
}

.donut-complex--loaded text {
  opacity: 1;
}
/*# sourceMappingURL=index.6569c7e1.css.map */
